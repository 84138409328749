<template>
  <div id="app">
    <table>
      <thead>
        <tr>
          <th>Element</th>
          <th>Résultat</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ceci est un test</td>
          <td>{{ status }}</td>
        </tr>
      </tbody>
    </table>
    <button @click="read">Passer la check list</button>
    <br />
    <span>{{ msg }}</span>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  data() {
    return {
      status: 'TODO',
      msg: ''
    }
  },
  methods: {
    read() {
      this.status = "..."
      this.msg = "SpeechSynthesisUtterance"
      var self = this
      var synth = window.speechSynthesis
      var utterThis = new SpeechSynthesisUtterance("Ceci est un test")
      synth.speak(utterThis)

      utterThis.onend = function(event) {
        self.msg = 'SpeechSynthesisUtterance after ' + event.elapsedTime  + 'seconds'

        var choix = [ 'oui' , 'non' , 'ok', 'ko', 'pas bon', 'ne marche pas', 'marche pas', 'marche', 'fonctionne', 'ne fonctionne pas', 'fonctionne pas', 'ça marche']
        var grammar = '#JSGF V1.0; grammar choix; public <choix> = ' + choix.join(' | ') + ' ;'

        var recognition = new window.webkitSpeechRecognition()
        var speechRecognitionList = new window.webkitSpeechGrammarList()

        speechRecognitionList.addFromString(grammar, 1)
        recognition.grammars = speechRecognitionList
        recognition.continuous = false
        //recognition.lang = 'en-US'
        recognition.lang = 'fr-FR'
        recognition.interimResults = false
        recognition.maxAlternatives = 1
        recognition.start()

        recognition.onresult = function(event) {
          var choisi = event.results[0][0].transcript
          console.log('Result received: <' + choisi + '>')
          self.msg = 'webkitSpeechRecognition : <' + choisi + '>'

          const ok = ['oui', 'ok', 'ça marche', 'marche', 'fonctionne'];
          const ko = ['non' , 'ko', 'pas bon', 'ne marche pas', 'marche pas', 'ne fonctionne pas', 'fonctionne pas'];
          if (ok.includes(choisi)) {
            self.status = "OK"
            //self.msg = this.msg + " // OK"
            console.log('OK')
          }
          if (ko.includes(choisi)) {
            self.status = "KO"
            //self.msg = self.msg + " // KO"
            console.log('KO')
          }
        }

        recognition.onspeechend = function() {
          //self.msg = "recognition.stop()"
          recognition.stop()
        }

        recognition.onnomatch = function() {
          console.log('Choix non reconnu.')
          self.msg = "Choix non reconnu."
        }

        recognition.onerror = function(event) {
          console.log('Error occurred in recognition: ' + event.error)
          self.msg = 'Erreur : ' + event.error
        }
      }

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
